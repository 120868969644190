@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@400;500&display=swap');


* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  text-rendering: geometricPrecision;
}

body {
  background: white;
  font-family: "Instrument Sans", sans-serif;
}

p {
  font-size: 1.3rem;
  line-height: 2.6rem;
  font-weight: 400;
  letter-spacing: -0.2px;
  color: #191919;
}

h4 {
  font-size: 1.1rem;
  margin-bottom: 0;
  font-weight: 500;
}

a {
  pointer-events: all;
  color: black;
}

.container {
  max-width: 45%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: justify;
  padding: 50px;

  background: rgb(255,255,255);
background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 5%, rgba(255,255,255,1) 95%, rgba(255,255,255,0) 100%);
}

.small-text {
  width: 100%;
  text-align: center;
  margin: 0 auto;
}

.bold {
  font-weight: 500;
  font-style: normal;
}

@media only screen and (max-width: 1200px) {
  .container {
    max-width: 90%;
  }
}







#slit-container {
  position: fixed;
  z-index: -19;
  width: 100%; 
  height: 100%; 
}























